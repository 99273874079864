import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import helpers from "../../../../../helpers";
import MultiDropdown from '../../../../global/multiDropdown/multiDropdown';
import { changeSetting } from "../../../../../redux/reducers/customConfig/customConfigActions";
import { translatePrice } from '../../../../../helpers/translation';
import { FaLock } from "react-icons/fa";

function EditTextDecorationMethod(props) {

  let locationContent = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  let bpLocation = _.find(props.baseProduct.locations, { code: locationContent.code });
  let bpAllowedEntity = _.find(bpLocation.allowedEntities, { key: locationContent.entity.type });

  const handleOnChange = (e) => {
    let decorationMethodName = e.slice(0, -7);
    let selectedDecorationMethod = _.find(bpAllowedEntity.allowedDecorationMethods, { name: decorationMethodName });
    props.changeSetting(props.customConfig.locations, props.navigation.activeLocation, { setting: "decorationMethod", value: selectedDecorationMethod.key }, props.baseProduct);
  }
  const handleOnHover = () => {
  }
  const handleOnHoverOff = () => {
  }

  let label = "Decoration Method";
  let currentIndex = 0;
  let location = _.find(props.customConfig.locations, { code: props.navigation.activeLocation });
  let key = location.entity.decorationMethod;
  let options = _.map(bpAllowedEntity.allowedDecorationMethods, (x) => {
    return (x.name);
  });
  if (options.length > 1) {
    currentIndex = _.findIndex(bpAllowedEntity.allowedDecorationMethods, { key });
    const activePrice = helpers.loadedDefs.getDefaultPrice(bpAllowedEntity.allowedDecorationMethods[currentIndex].key, location.entity.type);
    var newOptions = [];
    for (let o in bpAllowedEntity.allowedDecorationMethods) {
      const key = bpAllowedEntity.allowedDecorationMethods[o].key;
      const entity = location.entity.type;
      const price = helpers.loadedDefs.getDefaultPrice(key, entity);
      let x = bpAllowedEntity.allowedDecorationMethods[o];
      let name;
      let standardDisplayText;
      const nm = _.get(x, "name");
      const isLower = activePrice > price;
      const displayPrice = translatePrice(isLower ? (activePrice - price) : (price - activePrice));
      const prefix = isLower ? "-$" : "+$";
      name = `${nm} ${prefix + displayPrice}`;
      standardDisplayText = nm;
      newOptions.push({ key: x.key, name, price, standardDisplayText });
    }
    options = _.map(newOptions, (x) => {
      return (x.name);
    });
  }
  // const value = _.get(options, [[currentIndex]]);
  let value = _.find(bpAllowedEntity.allowedDecorationMethods, { key: locationContent.entity.decorationMethod }).name;
  let rightLabel = null;
  let menuHeader = null;

  if (options.length > 1) {
    return (

      <div className="EditTextDecorationMethod" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-decoration pl-0 pr-0 pt-2">
            <label className="decoration-label">{"TYPE"}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
              // label={label}
              rightLabel={rightLabel}
              menuHeader={menuHeader}
              value={value}
              options={options}
              onChange={handleOnChange}
              onHover={handleOnHover}
              onHoverOff={handleOnHoverOff}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="EditTextDecorationMethod" style={{ marginTop: "15px", position: "relative" }}>
        <div className="row pl-4">
          <div className="col-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 label-padding-decoration pl-0 pr-0 pt-2">
            <label className="decoration-label">{"TYPE"}</label>
          </div>
          <div className="col-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <MultiDropdown
              customClass="multidropdown-menu"
            // label={label}
            />
            <div className="input-group">
              <input value={value} className="text-height-number-input form-control border-right-0" disabled />
              <div className="input-group-append ">
                <span className="input-group-text border-left-0 append-border"><FaLock /></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

const reduxActions = {
  changeSetting
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig
  }
}

export default connect(mapStateToProps, reduxActions)(EditTextDecorationMethod);

