import React, { Component } from 'react'; 
import { SearchSVG } from "../../../assets/icons/SsearchSVG";

export class DesignHeader extends Component {
  render() {
    return (<div className="designHeader d-flex justify-content-between ">
      <div>       
         <div className="design-header-title">My Designs</div>
      </div>
      <div className="my-design-search-box input-group mb-3">
          <input type="text" className="form-control"  onChange={(e) => {this.props.search(e.target.value)}}/>
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon2">
            <SearchSVG />
            </span>
          </div>
      </div>
    </div>
    )
  }
}

export default DesignHeader; 