import React, { Component } from 'react';
export default class DesignTableHeader extends Component {
  render() {
    return (
    <div className = "row design-table-header align-items-center">
      <div className = "col-2 preview-header">Preview</div>
      <div className = "col-2 name-header d-flex flex-row align-items-center">
        <div>Name</div>
      </div>
      <div className = "col-2 ref-num-header d-flex flex-row align-items-center">
        <div>Reference #</div>
      </div>
      <div className = "col-2 date-header d-flex flex-row align-items-center">
      <div>Date Updated</div>
      </div>
      <div className = "col-4 actions-header"></div>
    </div>); 
  }
}
