import React, { Component } from 'react'; 
import { connect } from 'react-redux'
import _ from 'lodash';
import helpers from '../../../helpers';
import {messageIframe} from '../../../redux/reducers/notifications/notificationsActions';
import DesignHeader from '../designHeader/designHeader';
import DesignTableHeader from './designTableHeader/designTableHeader';
import DesignTableItem from './designTableItem/designTableItem';
import DesignPagination from './designPagination/designPagination';
import LoadingSVG from '../../../assets/icons/LoadingSVG';
import {saveModal, shareModal} from "../../../redux/reducers/modal/modalActions";



 class DesignTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };

    this.setPage = this.setPage.bind(this); 
    this.editItem = this.editItem.bind(this); 
    this.search = this.search.bind(this); 
  }


  search(val) {
    this.props.search(val); 
    this.setState({ page:1 });
  }

  setPage(page) {
    this.setState({ page });
    this.props.pagination( page ); 
  }

  editItem(refId) {
    this.props.messageIframe( 'edit-design', refId );
  }
  openShareModal=(rowConfigId, imgUrl)=>{
    //this.props.saveModal('Dealer', true, rowConfigId, imgUrl);
    this.props.shareModal('Dealer', true, rowConfigId, imgUrl);
  }

  renderItems(){
    
    if ( this.props.totalCount > 0 ){
      let configItems = this.props.data || [];
      let items = [];
      for ( let d=0;  d < configItems.length; d++ ){
        let configItem = configItems[d];
        let updatedAtStr = helpers.display.formatDateTime(configItem.updatedAt, 'LLL');
  
        // get first preview image url
        let firstPreview = _.find(configItem && configItem.previews, v => { return v && v.url });
        let imageUrl = _.get(firstPreview, 'url', './images/test-configs/saved-config-product.png');
        items.push(
          <DesignTableItem
            key={d}
            imageUrl={imageUrl}
            name={configItem.name}
            webRef={configItem.webRef}
            configId={configItem.configId}
            date={updatedAtStr}            
            openModal={this.openShareModal}
            //openModal = {this.props.saveModal('Dealer', true, configItem.configId, imageUrl)}
            edit={this.editItem} /> );
      }
      return items; 
    } else {
      return ( 
      <div className="d-flex justify-content-center">
        <div className="no-designs">No Designs Found</div>
      </div> ); 
    }
  }

  renderPagination(){
    if( this.props.totalCount > 0 ){
      return (  <DesignPagination
                  totalEntries={this.props.totalCount}
                  current={this.state.page}
                  total={this.props.totalCount / 5}
                  change={this.setPage}/> ); 
    }
      
    return null; 
  }

  render() {   
    if (!this.props.dataLoaded) {
      return (
        <div className="d-flex justify-content-center">
          <div className="no-designs">Loading Designs</div>
          <div><LoadingSVG height="70" width="70" fill="black" /></div>
        </div>
      );
    } else {
      return (
        <div>
          <DesignHeader search={this.search} sortValue={() => {}} />
          <div className="design-table">
            <DesignTableHeader  sortValue={() => {}} />
            { this.renderItems() }
            { this.renderPagination() }
          </div>
        </div>);
    }
  }
}
let reduxActions = {
  saveModal,
  shareModal,
  messageIframe,
}

export default connect(null, reduxActions)(DesignTable); 
