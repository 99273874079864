import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import ArtGalleryCard from './artGalleryCard/ArtGalleryCard';
import helpers from '../../../../../helpers';
import { setPendingLocation, setActiveLocation, setActiveTab, setView } from '../../../../../redux/reducers/navigation/navigationActions';

class ArtGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ""
    }
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
  }

  componentDidMount() {
    let entityKey = "custom-art";
    let bestLocation;
    let activeTab = this.props.navigation.activeTab;
    if (activeTab.page != "sub-add-art" && activeTab.subTab === "add-art") {
      bestLocation = this.findBestEmptyLocation(entityKey)
      if (bestLocation === null) {
        bestLocation = this.findBestFilledLocation(entityKey);
      }
      this.setToBestLocation(bestLocation, entityKey);
      //  this.props.setActiveLocation(code,this.props.navigation);
      if (this.props.navigation.activeLocation !== bestLocation.view) {
        this.props.setView(bestLocation.view);
      }
    }
  }


  findBestEmptyLocation = (entityKey) => {
    let views = helpers.locations.getAllViews();
    let bestLocation = null;
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestEmptyLocationByEntityType(entityKey, this.props.navigation.activeView);

    if (!bestLocation) {
      //  if there is no best location on current view search all views
      _.some(views, (view) => {
        bestLocation = helpers.locations.findBestEmptyLocationByEntityType(entityKey, view.key);
        if (bestLocation) { return true; }
      });
    }

    return bestLocation;
  }

  findBestFilledLocation = (entityKey) => {
    let views = helpers.locations.getAllViews();
    let bestLocation = null;
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestFilledLocation(entityKey, this.props.navigation.activeView);

    if (!bestLocation) {
      //  if there is no best location on current view search all views
      _.some(views, (view) => {
        bestLocation = helpers.locations.findBestFilledLocation(entityKey, view.key);
        if (bestLocation) { return true; }
      });
    }

    return bestLocation;
  }

  setToBestLocation = (bestLocation, entityKey) => {
    let entityDefaultValues = _.find(bestLocation.allowedEntities, { key: entityKey });
    if (entityDefaultValues) {
      let value = entityDefaultValues.placeholderValue;
      if (entityKey === 'custom-text') {
        if (this.props.navigation.customTextInput) {
          value = this.props.navigation.customTextInput;
        }
      }
      let artAsset = null;
      let userArtAssets = helpers.product.getStateVal('user.userArtAssets');
      if (userArtAssets.length > 0) {
        artAsset = userArtAssets[0];
      }
      let pendingLocation = helpers.navigation.makePendingLocation(entityDefaultValues.type, value, bestLocation.view, bestLocation.code, artAsset);
      this.props.setPendingLocation(pendingLocation);
      this.props.setActiveLocation(bestLocation.code);
      this.props.setView(bestLocation.view);
    }
  }
  searchValue = "";
  handleOnInputChange(event) {
    this.setState({ value: event.target.value });
  }

  artOptions = (this.props.count == 4) ? true : false;
  cards = [];
  generateArtGalleryCards = () => {
    if (this.state.value.length > 0) {
      this.cards = _.map(this.props.user.userArtAssets, (x, i) => {
        if ((x.id == this.state.value) || (x.name.includes(this.state.value))) {
          return (
            <ArtGalleryCard key={i} item={x} artOptions={this.artOptions} />
          );
        }
      });
    } else {
      if (this.props.count === "4") {
        this.cards = _.map(this.props.user.userArtAssets, (x, i) => {
          if (i < this.props.count) {
            return (
              <ArtGalleryCard key={i} item={x} artOptions={this.artOptions} />
            );
          }
        });
      } else if (this.props.count === "10") {
        this.cards = _.map(this.props.user.userArtAssets, (x, i) => {
          return (
            <ArtGalleryCard key={i} item={x} artOptions={this.artOptions} />
          );
        });
      }

    }
    return this.cards;
  }

  render() {
    let artGalleryClass = this.artOptions ? "ArtGalleryOptions" : "ArtGallery";
    let searchArt = this.artOptions ? "" : (<div>
      <input type="search" value={this.state.value} className="form-control input-text bg-white"
        placeholder="Search by name or art reference#" onFocus={(e) => e.target.placeholder = ''}
        onBlur={(e) => { e.target.placeholder = 'Search by name or art reference#' }}
        onChange={this.handleOnInputChange} id="input-search" />
    </div>)

    return (
      <div>
        {searchArt}
        <div className={`${artGalleryClass}`}>
          {this.generateArtGalleryCards()}
        </div>
      </div>
    )
  }

}

const reduxActions = {
  setPendingLocation,
  setActiveLocation,
  setActiveTab,
  setView
}

function mapStateToProps(state) {
  return {
    user: state.user,
    navigation: state.navigation,
  }
}

export default connect(mapStateToProps, reduxActions)(ArtGallery);