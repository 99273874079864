import React, { Component } from "react";
import { connect } from 'react-redux';
import { initLaunchContext } from "../../../../../src/redux/reducers/launchContext/launchContextActions.js";
import { closeModal } from "../../../../redux/reducers/modal/modalActions";
import Table from "../../../global/table/table";
import helpers from "../../../../helpers";
import api from "../../../../api";
import _ from "lodash";
import AppConstants from '../../../../constants';
import makeReadable from "../../../global/table/tableFormatter";
import { translatePrice } from '../../../../helpers/translation';

class ItemTotalModal extends Component {

    constructor(props) {
        super(props);
        let sku, skuPrice, productPrice = 0;
        if (this.props.baseProduct.configurationType === 'custom-sub')
            sku = _.find(this.props.baseProduct.skus, x => (x.size === 'L'));
        else if (this.props.baseProduct.configurationType === 'stock-dec')
            sku = _.find(this.props.baseProduct.skus, x => (x.size === 'L') && (x.color === this.props.customConfig.color));

        if (sku) {
            if (this.props.baseProduct.baseProductPrices && this.props.baseProduct.baseProductPrices !== null) {
                skuPrice = _.find(this.props.baseProduct.baseProductPrices, p => p.sku === sku.sku);
                productPrice = productPrice + (skuPrice ? skuPrice.price : 0);
            }
        }

        if (productPrice > 0) {
            productPrice = Number(productPrice).toFixed(2);
        }
        this.state = {
            qty: this.props.contentType.quantityNumber,
            itemTotal: this.props.contentType.itemTotal,
            baseProductPrice: this.props.contentType.baseProductPrice,
            defaultPrice: productPrice,
            dollars: this.props.contentType.dollars,
            cents: this.props.contentType.cents,
            hasTableData: this.props.contentType.hasTableData,
            tableConfig: this.props.contentType.tableConfig,
            tableData: this.props.contentType.tableData,
        };
    }

    async handleOnChange(val) {
        let totalSizes = +this.state.qty;
        let value = +val;
        let dollars = this.state.dollars;
        let cents = this.state.cents;
        let decorationPricing = helpers.pricing.getDecorationPricingRequestDataFromState();
        decorationPricing.quantity = Number(value);
        let res = await api.decorationPricing.calcPrices(decorationPricing);

        let resPricing = _.cloneDeep(res);
        _.each(resPricing && resPricing.entities, entity => {
            let isInk = entity && entity.decorationMethod && _.includes(AppConstants.InkDecorationMethods, entity.decorationMethod);
            if (isInk) {
                entity.ink = entity.decorationMethod;
            }
            let value = entity.priceCents;
            value = (value + '').replace(/[^\d.-]/g, '');
            value = parseFloat(value);
            value = value ? value / 100 : 0;
            entity.priceCents = value.toFixed(2);
        });

        let tableData1 = makeReadable(resPricing, this.state.tableConfig);
        this.state.tableData = tableData1;
        let priceCents = res ? res.subtotalCents : 0;
        if (priceCents <= 0) { priceCents = 0; }
        let priceStr = translatePrice(priceCents);
        let parts = _.split(priceStr, '.');
        dollars = _.get(parts, 0, '0');
        cents = '.' + _.get(parts, 1, '00');
        let baseProductPrice = this.state.baseProductPrice;
        let itemTotal = +this.state.itemTotal;
        let priceDiff = 0;
        let total = 0;
        if (value !== totalSizes) {
            priceDiff = Math.abs(value - totalSizes) * this.state.defaultPrice;
            if (value > totalSizes) {
                total = (+itemTotal + +priceDiff).toFixed(2)
            } else {
                if (value > 1) {
                    total = (+itemTotal - +priceDiff).toFixed(2);
                } else {
                    if (value <= 1) {
                        total = (+this.state.defaultPrice + +`${this.state.dollars}${this.state.cents}`).toFixed(2);
                    }
                }
            }
        }
        this.setState({
            qty: value,
            // itemTotal: value > totalSizes ? (+itemTotal + +priceDiff).toFixed(2) : +itemTotal === 11.3 ? (+itemTotal + 0.00).toFixed(2) : (+itemTotal - +priceDiff).toFixed(2),
            itemTotal: total,
            dollars: dollars,
            cents: cents
        })
    }

    render() {
        this.state.qty = this.state.qty > 0 ? this.state.qty : 1;
        return (
            <div>
                <div className="col-12 d-flex tooltip-header">
                    <div className="col-4 item-summary">
                        DECORATION CHARGES
                    </div>
                    <div className="col-6.5 stock-popup-header">
                        Enter estimated pieces to calculate quantity breaks
                    </div>
                    <div className="col-1.5">
                        <input style={{
                            border: '1px solid #CFCFCF',
                            fontFamily: 'Slate Std Bold', textAlign: 'center', marginLeft: '10px', width: '45px', padding: '4px 0px 4px 4px'
                        }} type="number" value={this.state.qty} onChange={e => this.handleOnChange(e.target.value)} />
                    </div>
                </div>

                <div className="item-summary-table fillx">
                    {
                        (!this.state.hasTableData) ? (
                            <div className="summary-table-empty-data-message">
                            </div >
                        ) : (
                            <Table
                                config={this.state.tableConfig}
                                tableData={this.state.tableData}
                            />
                        )
                    }
                </div>

                <div className="d-flex stock-tooltip-footer">
                    <div className="col-6 stock-tooltip-note">
                        <p>PLEASE NOTE: Decoration price per piece is an estimate pending Art Department review.
                            We will reach out if any prices change and/or require your sign-off.</p>
                    </div>
                    <div className="col-6 row stock-details">
                        <div className="col-8 item-data">
                            Product
                        </div>
                        <div className="col-4 item-data">
                            ${this.state.defaultPrice}
                        </div>
                        <div className="col-8 item-data">Decoration Fee </div>
                        <div className="col-4 item-data">
                            ${this.state.dollars}{this.state.cents}
                        </div>
                        <div className="col-8 stock-item-total">ITEM TOTAL</div>
                        <div className="col-4 item-data">
                            ${this.state.itemTotal}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = {
    initLaunchContext,
    closeModal
};

function mapStateToProps(state) {
    return {
        reduxState: state,
        baseProduct: state.baseProduct,
        customConfig: state.customConfig,
        launchContext: state.launchContext,
        changeDesignColor: state.changeDesignColor
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemTotalModal);