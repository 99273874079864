import React from "react";
import helpers from "../../../../../helpers";
import _ from "lodash";
import { setActiveTab, setActiveLocation, setView, previewSample, setPendingLocation } from "../../../../../redux/reducers/navigation/navigationActions";
import { SetEditDecorationType, SetDecorationSubtab, SetPrevLocationDetails } from "../../../../../redux/reducers/decoration/decorationAction";
import { connect } from "react-redux";
import { addPendingLocationToLocations, changeSetting } from '../../../../../redux/reducers/customConfig/customConfigActions';
import pluralize from "pluralize";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";


import EditDrawer from "../../editDrawer/EditDrawer";
import { TransitionStatuses } from "reactstrap/lib/utils";

class LocationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fill: "black"
    }
  }

  findViewName() {
    let { view } = this.props.data;
    let { views } = this.props.baseProduct;
    let value = _.find(views, { key: view });
    return (value.name) ? value.name : view;
  }

  componentDidMount() {

  }

  findTotalColors() {
    const numDecorationColors = _.get(this.props, "data.entity.numDecorationColors");
    const count = _.parseInt(numDecorationColors, null);
    return count ? `${count} ${pluralize("Color", count)}` : "";
  }

  displaySample() {
    // if we have a URL for this entity item, then display it.  
    // otherwise use the default text for the type.
    let url = _.get(this.props, "data.entity.url");
    let entityType = _.get(this.props, "data.entity.type");
    let textValue = _.get(this.props, "data.entity.value");
    let flipValue = _.get(this.props, "data.entity.flipArt");
    let rotateValue = _.get(this.props, "data.entity.rotateArt");
    let retval = null;
    switch (entityType) {
      case "player-name":
        retval = url ? (<img src={url} alt="player-name" style={{ maxHeight: "45px", maxWidth: "100%" }} />) : textValue;
        break;

      case "player-number":
        retval = url ? (<img src={url} alt="player-number" style={{ maxHeight: "45px", maxWidth: "100%" }} />) : textValue;
        break;

      case "custom-text":
        retval = url ? (<img src={url} alt="custom-text" style={{ maxHeight: "45px", maxWidth: "100%" }} />) : textValue;
        break;

      case "custom-art":
        {
        if(flipValue && rotateValue>0){
          retval = (<div className="art-preview" style={{ height: "100px" }}>
          <img src={url} alt="" style={{ maxHeight: "100%", maxWidth: "100%", transform: `rotate(${rotateValue}deg) scaleX(-1)`, height:"52px",width:"52px", padding:"2px"}} /> 
           </div>);

        }else if(!flipValue && rotateValue>0){
          retval = (<div className="art-preview" style={{ height: "100px" }}>
          <img src={url} alt="" style={{ maxHeight: "100%", maxWidth: "100%",  transform: `rotate(${rotateValue}deg)`, height:"52px",width:"52px", padding:"2px"}} /> 
            </div>);
        }else if(flipValue && !rotateValue>0){
          retval = (<div className="art-preview" style={{ height: "100px" }}>
          <img src={url} alt="" style={{ maxHeight: "100%", maxWidth: "100%", transform: "scaleX(-1)", padding:"2px"}} /> 
            </div>);

        }else{
          retval=(<div className="art-preview" style={{ height: "100px" }}>
            <img src={url} alt="" style={{ maxHeight: "100%", maxWidth: "100%" }} />
          </div>);
        }
        break;
      }
      default:
        break;
    }

    return retval;
  }

  getDisplayNames() {
    let { data } = this.props;
    let locationInfo = helpers.product.getProductLocationInformation(this.props.baseProduct.locations, data.code, data.entity.type);
    let name = data.entity.decorationMethod;
    if (name && locationInfo) {
      let entityIndex = _.findIndex(locationInfo.allowedEntities, { type: data.entity.type });
      if (entityIndex >= 0) {
        let productEntityInfo = locationInfo.allowedEntities[entityIndex];
        let decIndex = _.findIndex(productEntityInfo.allowedDecorationMethods, { key: name });
        if (decIndex >= 0) {
          name = productEntityInfo.allowedDecorationMethods[decIndex].name;
        }
      }
      return name;
    }
    return "";
  }

  onHover() {
    this.setState({ fill: "red" });
  }

  offHover() {
    this.setState({ fill: "black" });
  }

  findBestEmptyLocation = (entityKey) => {
    let views = helpers.locations.getAllViews();
    let bestLocation = null;
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestEmptyLocationByEntityType(entityKey, this.props.navigation.activeView);

    if (!bestLocation) {
      //  if there is no best location on current view search all views
      _.some(views, (view) => {
        bestLocation = helpers.locations.findBestEmptyLocationByEntityType(entityKey, view.key);
        if (bestLocation) { return true; }
      });
    }

    return bestLocation;
  }

  findBestFilledLocation = (entityKey) => {
    let views = helpers.locations.getAllViews();
    let bestLocation = null;
    // if location does not support entity type find next available location
    bestLocation = helpers.locations.findBestFilledLocation(entityKey, this.props.navigation.activeView);

    if (!bestLocation) {
      //  if there is no best location on current view search all views
      _.some(views, (view) => {
        bestLocation = helpers.locations.findBestFilledLocation(entityKey, view.key);
        if (bestLocation) { return true; }
      });
    }
    return bestLocation;
  }



  setToBestLocation = (bestLocation, entityKey) => {
    let entityDefaultValues = _.find(bestLocation.allowedEntities, { key: entityKey });
    if (entityDefaultValues) {
      let value = entityDefaultValues.placeholderValue;
      if (entityKey === 'custom-text') {
        if (this.props.data.entity.value) {
          value = this.props.data.entity.value;
        }
      } else if (entityKey === 'player-name') {
        if (this.props.data.entity.value) {
          value = this.props.data.entity.value;
        }
      } else if (entityKey === 'player-number') {
        if (this.props.data.entity.value) {
          value = this.props.data.entity.value;
        }
      }
      let isArtAsset = _.find(this.props.user.userArtAssets, { id: this.props.data.entity.userArtId });

      if (entityDefaultValues.type !== 'custom-art') {
        this.pendingLocation = helpers.navigation.makePendingLocation(entityDefaultValues.type, value, bestLocation.view, bestLocation.code);
        //  this.pendingLocation.entity = this.props.data.entity;
        this.pendingLocation.entity.font = this.props.data.entity.font;
        this.pendingLocation.entity.textEffect = this.props.data.entity.textEffect;
        this.pendingLocation.entity.patternFill = this.props.data.entity.patternFill;
        this.pendingLocation.entity.capabilities = this.props.data.entity.capabilities;
        this.pendingLocation.entity.decorationMethod = this.props.data.entity.decorationMethod;

        let locationCodeA = this.pendingLocation.code;
        let groupName = this.props.baseProduct.locations.filter(v => v.code === this.pendingLocation.code);
        this.props.previewSample(this.props.baseProduct, locationCodeA, null, this.pendingLocation);
        this.props.addPendingLocationToLocations(this.props.customConfig.locations, this.pendingLocation, groupName);

      }
      else {
        this.pendingLocation = helpers.navigation.makePendingLocation(entityDefaultValues.type, "", bestLocation.view, bestLocation.code, isArtAsset);
        let locationCodeA = this.pendingLocation.code;
        let groupName = this.props.baseProduct.locations.filter(v => v.code === this.pendingLocation.code);
        this.props.previewSample(this.props.baseProduct, locationCodeA, null, this.pendingLocation);
        this.props.addPendingLocationToLocations(this.props.customConfig.locations, this.pendingLocation, groupName);
      }
      this.props.setPendingLocation(this.pendingLocation);
      this.props.setActiveLocation(bestLocation.code);
      this.props.setView(bestLocation.view);
    }

  }


  duplicateDecoration() {
    let subTab = "";
    let { data } = this.props;
    this.props.navigation.duplicate = this.props.data;

    // It will get existing decoration location when we click on duplicate
    let locationInfo = helpers.product.getProductLocationInformation(this.props.baseProduct.locations, data.code, data.entity.type);
    let locationContent = _.find(this.props.customConfig.locations, { code: locationInfo.code });
    this.props.SetEditDecorationType(locationContent.entity.type)
    // get next best possible location for custom-text or playername or number
    if (locationContent.entity.type === 'custom-text' || locationContent.entity.type === 'player-name' || locationContent.entity.type === 'player-number') {
      subTab = "TEXT";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-text", page: "duplicate" }
      this.props.setActiveTab(tabObj)
      let bestEmptyLocation = null;
      let entityKey = this.props.data.entity.type;
      bestEmptyLocation = this.findBestEmptyLocation(entityKey);
      if (bestEmptyLocation) {
        this.setToBestLocation(bestEmptyLocation, entityKey);
      }

    } else {
      // get next best possible location for custom-art
      subTab = "GRAPHIC";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-art", page: "duplicate-art" }
      this.props.setActiveTab(tabObj)
      let bestEmptyLocation = null;
      let entityKey = this.props.data.entity.type;
      bestEmptyLocation = this.findBestEmptyLocation(entityKey);
      if (bestEmptyLocation) {
        this.setToBestLocation(bestEmptyLocation, entityKey);
      }
    }
  }

  handleEdit() {
    let subTab = "";
    let { data } = this.props;
    let locationInfo = helpers.product.getProductLocationInformation(this.props.baseProduct.locations, data.code, data.entity.type);
    let locationContent = _.find(this.props.customConfig.locations, { code: locationInfo.code });
    let prevLocationsContent = _.cloneDeep(this.props.customConfig.locations);
    let prevLocationContent = _.find(prevLocationsContent, { code: locationInfo.code });
    this.props.setView(locationInfo.view);
    this.props.setActiveLocation(locationInfo.code, this.props.navigation);
    this.props.SetEditDecorationType(locationContent.entity.type);
    this.props.SetPrevLocationDetails(prevLocationContent);
    // this.props.decoration.prevLocation = locationContent;
    this.props.changeSetting(this.props.customConfig.locations, locationContent.code,  { setting: "editEntity", value: false })
    if (locationContent.entity.type === 'custom-text' || locationContent.entity.type === 'player-name' || locationContent.entity.type === 'player-number') {
      // this.props.SetPrevLocationDetails(locationContent);
      subTab = "TEXT";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-text", page: "edit" }
      this.props.setActiveTab(tabObj)
    } else {
      subTab = "GRAPHIC";
      this.props.SetDecorationSubtab(subTab);
      let tabObj = { tab: this.props.navigation.activeTab.tab, subTab: "add-art", page: "edit" }
      this.props.setActiveTab(tabObj)
    }
  }

  checkLocations() {
    let UpdatedLocations = this.props.customConfig.locations;
    UpdatedLocations = _.filter(UpdatedLocations, loc =>
        // loc.saveEntity === true || (this.props.decoration.editDecorationType !== "" && loc.editEntity === true)
        loc.saveEntity === true
    );
    this.props.customConfig.locations = UpdatedLocations;
}

  render() {
    let { data } = this.props;
    this.checkLocations();
    // let viewName = this.findViewName();
    this.props.navigation.duplicate = this.props.data;
    let location = _.find(this.props.baseProduct.locations, { "code": data.code })
    let decorationMethod = this.getDisplayNames();
    let size = _.get(this.props, "data.entity.size");

    return (<div>
      <div className="location-detail-box d-flex">
        <div className="text-location-detail flex-grow-1 tooltip-right">
          <div className="text-location-detail-header justify-content-between">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <div className="text-location-detail-type">
                      {helpers.product.renderDisplayName(location && location.allowedEntities, data.entity.type, "type").name}
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-end" onClick={() => this.duplicateDecoration()}>
                    <div className="text-location-detail-type">
                      {"Duplicate"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-location-detail-body" onClick={() => this.handleEdit()}>
            <div className="d-flex justify-content-center">
              <div >
                {this.displaySample()}
              </div>
            </div>
          </div>
          <div className="text-location-detail-footer justify-content-between">
            <div className="colors-type  justify-content-between">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-5">
                      {/* <div className="color-detail">{this.findTotalColors()}</div> */}
                      <div className="type-detail">{this.props.baseProduct.configurationType === "custom-sub" ? `Custom ${decorationMethod}` : decorationMethod}</div>
                    </div>
                    <div className="col-6">
                      <div className="text-location-detail-title" >
                        {/* {viewName} -  */}
                        {helpers.product.renderDisplayName(this.props.baseProduct.locations, data.code, "code").name}({size})
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row text-location-detail-cancel">
          <div className="col-12">
            <div className="row">
              <div className="col-12 pl-0 pb-3" onClick={() => this.handleEdit()}>
                <div>
                  <FaPencilAlt className="edit-icon" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 pl-0 pb-3">
                <div aria-label={this.props.deleteToolTip}
                  data-balloon-pos="left"
                  onClick={this.props.remove} >
                  <FaTrashAlt className="edit-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    baseProduct: state.baseProduct,
    customConfig: state.customConfig,
    decoration: state.decoration,
    user: state.user
  }
}

const outputActions = {
  setActiveLocation,
  setActiveTab,
  setView,
  setPendingLocation,
  // setCustomTextInput,
  previewSample,
  addPendingLocationToLocations,
  SetEditDecorationType,
  SetDecorationSubtab,
  SetPrevLocationDetails,
  changeSetting
}
export default connect(mapStateToProps, outputActions)(LocationDetail);
