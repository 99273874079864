import React, { Component } from "react";
import { connect } from 'react-redux';
import { initLaunchContext } from "../../../../../src/redux/reducers/launchContext/launchContextActions.js";
import { closeModal } from "../../../../redux/reducers/modal/modalActions";

class LeadTimeModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="d-flex stock-tooltip-footer">
                    <div className="col-12 stock-tooltip-note">
                        <span className="leadtime-summary">PLEASE NOTE:</span><span> For orders containing 200 items and
                         up, the Lead Time will be reviewed by a representative to ensure best delivery time available
                        and contact you with the details. For faster response, please contact <a className="leadtime-email"
                        href="mailto:decoration@foundersportgroup.com">decoration@foundersportgroup.com</a> directly
                        for a Lead Time quote.
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = {
    initLaunchContext,
    closeModal,
};

function mapStateToProps(state) {
    return {
        reduxState: state
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LeadTimeModal);