import React, { Component } from 'react'
//import { DesignTrashSVG } from "../../../../assets/icons/trashSVG"; 

export default class DesignTableItem extends Component {

  constructor(props){
    super(props); 
    this.state = {
      removeFill: "black"
    }

    this.editItem = this.editItem.bind(this); 
    this.shareItem = this.shareItem.bind(this); 
  }

  mouseOnRemove(){
    this.setState({removeFill: "red"}); 
  }  
  
  mouseOffRemove(){
    this.setState({removeFill: "black"}); 
  }

  editItem(){
    this.props.edit(this.props.configId); 
  }
  shareItem(){
    this.props.openModal(this.props.configId, this.props.imageUrl);
  }
  removeItem(){
    this.props.remove(this.props.refId);
  }

  render() {
    let { imageUrl, name, configId, date } = this.props
    return (
      <div className="row design-table-item d-flex align-items-center">
        <div className="col-2 design-item-preview flex-fill"> <img className="img-fluid" src={imageUrl} alt="testImage"></img> </div>
        <div className="col-2 design-item-name flex-fill">{name}</div>
        <div className="col-2 design-item-ref flex-fill">{configId}</div>
        <div className="col-2 design-item-date flex-fill">{date}</div>
        <div className="col-4 design-item-actions align-items-center flex-fill d-flex">
          <button className="design-item-edit btn" onClick={() => this.editItem()}>edit</button> 
          <button className="design-item-share btn" onClick={() => this.shareItem()}>share</button> 
          <div className="design-item-remove"
           onClick={() => this.removeItem()}
           onMouseOver={() => this.mouseOnRemove()}
           onMouseLeave={() => this.mouseOffRemove()}>
            {/*<DesignTrashSVG fill = {this.state.removeFill}/>*/}
          </div>
        </div>
      </div>
    )
  }
}
